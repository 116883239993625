<template>
	<div>
		<div class="tishi">{{ tishi }}</div>
		<div>
			<div class="list" v-for="item in list" :key="item">
				<div class="left">
					<p class="p1">{{ item.card_name }}</p>
					<p class="p2">{{ item.created_at }}</p>
				</div>
				<div class="listbtn">
					<van-button type="info" style="margin-right: 15px;" @click="atmpin(item.id)">设置ATM密码</van-button>
					<van-button type="warning" style="margin-right: 15px;">取消</van-button>
					<van-button type="primary">激活</van-button>
				</div>
				
			</div>
		</div>
    
    <div class="fixed">
			<van-button type="info" @click="goStep2">设置信息</van-button>
			<van-button type="primary" @click="getKfc">KYC验证</van-button>
			<van-button type="primary" @click="getAddInit">KYC提交</van-button>
			<van-button type="warning" @click="goApply">申请卡片</van-button>
		</div>
		<van-dialog
      v-model="show"
      title="个人信息"
      show-cancel-button
      @cancel="onCancel"
      @confirm="postInit"
    >
      <div>
				<!-- <van-image 
					class="myimg"
					:src="init.file_url_list[0]||''" 
					fit="contain" 
				/>
				<van-image 
					class="myimg"
					:src="init.file_url_list[1]||''" 
					fit="contain" 
				/> -->
				<van-field
					v-model="init.gender"
					label="性别"
					disabled
				/>
				<van-field
					v-model="init.birthday"
					label="生日"
					placeholder="请输入生日"
					disabled
				/>
				<van-field
					v-model="init.address"
					label="地址"
					placeholder="请输入地址"
					disabled
				/>
				<van-field
					:value="otext"
					is-link
					readonly
					label="职位"
					placeholder="请选择职位"
					@click="show = true"
				/>
				<van-popup v-model="show" round position="bottom">
					<van-picker
						:columns="olist"
						@cancel="show = false"
						@confirm="onConfirm"
						show-toolbar
					/>
				</van-popup>
				<van-field
					v-model="init.nationality_code"
					label="现国籍"
					disabled
				/>
				<van-field
					v-model="init.birth_country_code"
					label="出生地"
					disabled
				/>
				<van-field
					v-model="init.residence_country"
					label="居住地"
					disabled
				/>
			</div>
    </van-dialog>
	</div>
</template>

<script>
// status：1: 申请中，2: 审核通过，3：已发卡，4：已激活，5： 已实名，9：被驳回，10：已取消
	export default {
		name: 'personal_step3',
		data() {
			return {
				tishi:'',
				show: false,
				init:{
					address:"",
					birth_country_code:"",
					birthday:"",
					gender:"",
					nationality_code:"",
					residence_country:"",
					file_url_list:[]
				},
				list:[]
			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			}
			this.getList()
			this.tips()
			// 取消开卡状态
			// this.$axios({
			// 		method: 'post',
			// 		url: 'speedpay_card/cancel',
			// 		data: {
			// 			token:this.$route.query.token,
			// 			id:5
			// 		}
			// 	}).then ((res) => {
			// 		if (res.success) {
			// 			console.log(res)
			// 		}else {
			// 			this.$dialog.alert({
			// 				message: res.error_text
			// 			})
			// 		}
			// 	})
		},
		methods: {
			tips(){
				this.$axios({
					method: 'post',
					url: 'speedpay_user/tips',
					data: {
						token:this.$route.query.token
					}
				}).then ((res) => {
					if (res.success) {
						console.log(res)
						this.tishi=res.data.msg
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			atmpin(id){
				this.$axios({
					method: 'post',
					url: 'speedpay_card/atmpin',
					data: {
						token:this.$route.query.token,
						id:id
					}
				}).then ((res) => {
					if (res.success) {
						console.log(res)

						var url=decodeURI(res.data.redirectUrl)
						window.location.href=url
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
      // getList(){
			// 	this.$axios({
			// 		method: 'post',
			// 		url: 'speedpay_card/splist',
			// 		data: {
			// 			token:this.$route.query.token
			// 		}
			// 	}).then ((res) => {
			// 		if (res.success) {
			// 			this.list=res.data.rows
			// 		}else {
			// 			this.$dialog.alert({
			// 				message: res.error_text
			// 			})
			// 		}
			// 	})
			// },
			getList(){
				this.$axios({
					method: 'post',
					url: 'speedpay_card/list',
					data: {
						token:this.$route.query.token
					}
				}).then ((res) => {
					if (res.success) {
						this.list=res.data
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			getAddInit(){
				this.getCclist();
				this.show = true;
				this.$axios({
					method: 'post',
					url: 'speedpay_kyc/addInit',
					data: {
						token:this.$route.query.token
					}
				}).then ((res) => {
					if (res.success) {
						this.init = Object.assign({}, this.init, res.data);
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			getCclist(){
				this.$axios({
					method: 'post',
					url: 'speedpay_common/cclist',
					data: {
						token:this.$route.query.token
					}
				}).then ((res) => {
					if (res.success) {
						this.init = Object.assign({}, this.init, res.data);
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			postInit(){
				this.show = false;
				this.init.file_url_list=JSON.stringify(this.init.file_url_list)
				this.$axios({
					method: 'post',
					url: 'speedpay_kyc/add',
					data: {
						token:this.$route.query.token,
						...this.init
					}
				}).then ((res) => {
					if (res.success) {
						console.log(res)
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			onCancel() {
				// 取消操作的回调
				this.show = false;
			},
			getKfc(){
				this.$axios({
					method: 'post',
					url: 'speedpay_kyc/index',
					data: {
						token:this.$route.query.token
					}
				}).then ((res) => {
					if (res.success) {
						window.location.href=res.data.redirectUrl
					}else {
						this.$dialog.alert({
							message: res.error_text
						})
					}
				})
			},
			goStep2(){
				this.$router.push('/speedpay/papers?token='+this.$route.query.token +'&card_type='+this.$route.query.card_type)
			},
			goApply(){
				this.$router.push('/speedpay/apply?token='+this.$route.query.token +'&card_type='+this.$route.query.card_type)
			}
		},
	}
</script>
<style scoped>
.tishi{
	padding: 20px;
	background: #fff;
	margin-bottom: 15px;
}
.list{
	/* display: flex;
	justify-content: space-between; */
	background:white;
	padding: 20px;
}
.list .left .p2{
	font-size: 12px;
	color:#818181;
	margin-top: 5px;
}
.listbtn{
	text-align: right;
	margin-top: 20px;
}
  .fixed{
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 999;
		background: #fff;
		padding: 10px;
		box-sizing: border-box;
		box-shadow: 0px 0px 10px #ccc;
		display: flex;
		justify-content: space-around;
	}
</style>
